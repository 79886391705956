import { useState } from "react";
import Icon from "../Icons";
import "../../styles/input.css";

/**
 * Primary UI component for user interaction
 */
const InputField = (props) => {
  const { label, type, className, touched, error, value,placeholder,disabled,RightEndText,frontIcon } = props;

  const [showpassword, setShowpassword] = useState(false);

  return (
    <div
      className={`input_field ${value?.length > 0 ? "is-active" : ""} ${
        touched && error ? "has-errors" : ""
      } ${type === "tel" ? "is-number" : ""}  ${className}`}
    >
      {label && <p className="mb-2">{label}</p>}
      <div className="flex gap-2">
        {type == "email" || type == "password" || frontIcon && (
          <div className="my-auto h-4 w-4">
            {type === "email" ? <Icon id="email-icon" width="15px" height="16px" /> : type === "password" ? <Icon id="lock-icon" width="15px" height="15px" /> : frontIcon ? (<Icon id={frontIcon} width="15px" height="15px" />) : null}
          </div>
        )}
        <input
          placeholder={`${type === "tel" ? label : placeholder}`}
          {...props}
          type={showpassword ? "text" : type}
          className={`input-field ${disabled ? "disabled" : "" }`}
        />
        {type === "password" && (
          <div
            className="show-password cursor-pointer"
            onClick={() => setShowpassword(!showpassword)}
          >
            {showpassword ? <Icon id="show-password-icon" width="19px" height="18px" /> : <Icon id="hide-password-icon" width="19px" height="18px" />}
          </div>
        )}
        {RightEndText && (
          <p className="text-primary text-sm leading-4 my-auto">{RightEndText}</p>
        )}

      </div>
      {touched && error && <div className="error-message">{error}</div>}
    </div>
  );
};


export default InputField;

export const TextArea = (props) => {
  const { label, type, className, touched, error, value,placeholder,disabled } = props;

  return (
    <div
      className={`input_field ${value?.length > 0 ? "is-active" : ""} ${
        touched && error ? "has-errors" : ""
      } ${className}`}
    >
      {label && <p className="mb-2">{label}</p>}
      <div className="flex">
        <textarea 
          className={`textarea ${disabled ? "disabled" : "" }  hover:border  w-full`} 
          placeholder={`${type === "tel" ? label : placeholder}`}
          {...props}
        />
      </div>
      {touched && error && <div className="error-message">{error}</div>}
    </div>
  );
};
