import React from "react";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

export const ErrorToast = ({ message }) => (
  <p className="text-black text-xs">{message}</p>
);

export const SuccessToast = ({ message }) => (
  <p className="text-black text-xs">{message}</p>
);

export const WarningToast = ({ message }) => (
  <p className="text-black text-xs">{message}</p>
);

export const InfoToast = ({ message }) => (
  <p className="text-black text-xs">{message}</p>
);

export const toastError = (message) =>
  toast.error(<ErrorToast message={message} />);

export const toastSuccess = (message) =>
  toast.success(<SuccessToast message={message} />);

export const toastWarning = (message) =>
  toast.warning(<WarningToast message={message} />);

export const toastInfo = (message) =>
  toast.info(<InfoToast message={message} />);