import { ButtonLoader } from "../Loader";
import "../../styles/button.css";
import Icon from "../Icons";

export const Button = ({
  type,
  backgroundColor,
  disabled,
  size,
  name,
  className,
  label,
  isLoading,
  role,
  ...props
}) => (
  <button
    type={type}
    role={role}
    className={["btn", `btn--${size} btn--${name}`, `${className}`,`${disabled && className !== "opacity-20" ? "disabled text-black" : ""} flex justify-center`].join(" ")}
    style={backgroundColor && { backgroundColor }}
    disabled={disabled}
    {...props}
  >
    {props.iconId && (<Icon id={props.iconID} width={"24px"} height={"24px"} />)}
    {isLoading ? <ButtonLoader color="#fff"/> : label}
  </button>
);

export default Button;
