import { create } from 'zustand'
import { persist } from 'zustand/middleware'

export const useStore = create(persist(
    (set) => ({
      isLoggedIn: false,
      profileData: {},
      petOwner: {},
      petData: [],
      appointmentsData: [],
      notificationsData: [],
      jobData: [],
      sittersList: [],
      onboarding: {},
      tempData: null,
      setTempData: (payload) => set((state) => ({ tempData: payload })),
      setSittersList: (payload) => set((state) => ({ SitterList: payload })),
      setOnboarding: (payload) => set((state) => ({ onboarding: payload })),
      setProfileData: (payload) => set((state) => ({ profileData: payload })),
      setPetData: (payload) => set((state) => ({ petData: payload })),
      updatePetProfile: (payload) => set((state) => ({petData: [...state.petData, payload]})),
      setAppointmentsData: (payload) => set((state) => ({ appointmentsData: payload })),
      setNotificatonsData: (payload) => set((state) => ({ notificationsData: payload })),
      setJobData: (payload) => set((state) => ({ jobData: payload })),
      updateAppointmentsData: (payload) => set((state) => ({appointmentsData: [...state.appointmentsData, ...payload]})),
      logUserIn: () => set((state) => ({isLoggedIn: true})),
      logUserOut: () => set((state) => ({...state, isLoggedIn: false, profileData: {},petData: [],notificationsData: [],appointmentsData: [],sittersList: [], jobData: []})),
    }),
    {
      name: 'state'
    },
  ))