import * as Yup from "yup";

const passwordRegExp = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{6,}$/

export const LoginValidations = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email, please provide a valid email.")
      .required("Email is required"),
    password: Yup.string()
      .required("Password is required")
});

export const SignupValidations = Yup.object().shape({
        firstName: Yup.string()
        .min(2, "First Name must have a minimum of 2 characters")
        .max(20, "First Name must have a maximum of 20 characters")
        .required("First Name is required"),
        lastName: Yup.string()
        .min(2, "Last Name must have a minimum of 2 characters")
        .max(20, "Last Name must have a maximum of 20 characters")
        .required("Last Name is required"),
        email: Yup.string()
        .email("Invalid email, please provide a valid email.")
        .required("Email is required"),
        password: Yup.string()
        .min(6, "Password must be at least 6 characters")
        .matches(passwordRegExp, "Password must contain an uppercase letter,number and special character")
        .required("Password is required"),
        confirm_password:  Yup.string()
        .required('This is a required field.')
        .oneOf([Yup.ref('password')], 'Your passwords do not match.')
});

export const changePasswordValidation = () =>
  Yup.object().shape({
    password: Yup.string().required("This is a required field"),
    newpassword: Yup.string()
      .min(5, "Password must be atleast 5 characters")
      .required("This is a required field")
  });

export const EmailValidation = () =>
  Yup.object().shape({
    email: Yup.string()
      .email("Invalid email, please provide a valid email.")
      .required("Email is required")
  });

export const EditProfileValidation = Yup.object().shape({
  province: Yup.string(),
  city: Yup.string(),
  address: Yup.string(),
  short_term_price: Yup.string(),
  long_term_price: Yup.string(),
});

export const changePasswordValidations = () => Yup.object().shape({
  old_password: Yup.string()
  .required("Current password is required"),
  new_password: Yup.string().required('New password is required').matches(passwordRegExp,"Must be atleast 6 characters long and contain 1 uppercase character, a digit and a symbol"),
  confirm_new_password: Yup.string().required("This is a required field")
  .oneOf([Yup.ref('old_password')], 'Passwords must match')
});
export const resetPasswordValidations = () => Yup.object().shape({
  otp: Yup.string()
  .required("otp is required"),
  password: Yup.string()
  .min(8, "Password must be at least 8 characters")
  .matches(passwordRegExp, "Password must contain an uppercase letter,number and special character")
  .required("New password is required"),
  confirm_password: Yup.string()
  .required("confirmation is required").when("password", {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref("password")],
      "Passwords do not match "
    )
  }),
});

export const CompleteOnboardingValidation = Yup.object().shape({
    avatar: Yup.string().required("This is a required field"),
    age: Yup.string().required("This is a required field"),
    // sex: Yup.string().required("This is a required field"),
    bio: Yup.string().required("This is a required field"),
    phone_number: Yup.string().required("This is a required field"),
    address: Yup.string().required("This is a required field"),
    province: Yup.string().required("This is a required field"),
    city: Yup.string().required("This is a required field"),
    zipcode: Yup.string().required("This is a required field")
});
export const PetWellnessValidation = Yup.object().shape({
    vaccination_status: Yup.boolean(),
    medical_Conditions: Yup.string(),
    dietary_restrictions: Yup.string(),
    allergies: Yup.string()
});

export const PetBehaviourValidation = Yup.object().shape({
    children_interactions: Yup.string(),
    favorite_activities: Yup.string(),
    pet_interactions: Yup.string(),
    temperament: Yup.string()
});

export const PetCareValidation = Yup.object().shape({
    exercise_needs: Yup.string(),
    feeding_schedule: Yup.string(),
    special_care_instructions: Yup.string()
});

export const PetVetValidation = Yup.object().shape({
    name: Yup.string(),
    phone_number: Yup.string(),
});


export const CreatePetValidation = Yup.object().shape({
    images: Yup.string().required("This is a required field"),
    age: Yup.number().required("This is a required field"),
    gender: Yup.string().required("This is a required field"),
    breed: Yup.string().required("This is a required field"),
    size: Yup.string().required("This is a required field"),
    type: Yup.string().required("This is a required field"),
    name: Yup.string().required("This is a required field"),
    meta: Yup.object().shape({
        health_and_wellness: PetWellnessValidation,
        behaviour: PetBehaviourValidation,
        care_instructions: PetCareValidation,
        vet_contact: PetVetValidation
    })
});

export const ServicesValidation = Yup.object().shape({
    pets: Yup.array().required("This is a required field"),
    rate_card: Yup.array().required("This is a required field"),
});

export const RateCardValidation = Yup.object().shape({
    same_day_rate: Yup.number().required("This is a required field"),
    scheduled_rate: Yup.number().required("This is a required field"),
});

export const CreateAppointmentValidation = Yup.object().shape({
    title: Yup.string().required("This is a required field"),
    description: Yup.string().when('type', {
      is: (v) => v === 'ERRAND',
      then: (schema) => schema.required('This is a required field')
    }),
    start_time: Yup.string().required("This is a required field"),
    end_time: Yup.string().required("This is a required field"),
    start_date: Yup.string().when('type', {
        is: (v) => v === 'SCHEDULED',
        then: (schema) => schema.required('This is a required field')
    }),
    end_date: Yup.string().when('type', {
        is: (v) => v === 'SCHEDULED',
        then: (schema) => schema.required('This is a required field')
    }),
    proposed_rate: Yup.string().when('type', {
        is: (v) => v === 'ERRAND',
        then: (schema) => schema.required('This is a required field')
    }),
    is_multi_day: Yup.boolean(),
    type: Yup.string(),
    pet_ids: Yup.array(),
});

export const RenegotiateValidation = Yup.object().shape({
    proposed_rate: Yup.string().required("This is a required field"),
});

export const AddCardValidation = Yup.object().shape({
  card_number: Yup.string().max(16,"Invalid card number").required("This is a required field"),
  cardholder_name: Yup.string().required("This is a required field"),
  card_expiry: Yup.string().required("This is a required field"),
  card_cvc: Yup.string().max(3,"Invalid ccv").required("This is a required field")
});

export const JoinWaitlistValidation = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email, please provide a valid email.")
    .required("Email is required"),
    account_type: Yup.string().required("This is a required field")
});