import Button from "../components/Button";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Logo } from "../assets/logo.svg";
import { ReactComponent as Logo2 } from "../assets/Logo2.svg";
import Icon from "../components/Icons";
import "../styles/hovereffect.css";
import { useEffect, useRef, useState } from "react";

import image1 from "../assets/services1.jpg";
import image2 from "../assets/services2.jpg";
import image3 from "../assets/services5.jpg";
import image4 from "../assets/services6.jpg";

const Landing = () => {
  const navigate = useNavigate();
  const [isIntersecting, setIsIntersecting] = useState(true);
  const ref = useRef(null);
  const [isHovered, setIsHovered] = useState(false);

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       setIsIntersecting(entry.isIntersecting);
  //     },
  //     { rootMargin: "120px" }
  //   );
  //   //   console.log(isIntersecting);
  //   observer.observe(ref.current);

  //   return () => observer.disconnect();
  // }, [isIntersecting]);

  // useEffect(() => {
  //   if (isIntersecting) {
  //     ref.current.classList.add() querySelectorAll("div").forEach((el) => {
  //       el.classList.add("slide-in");
  //     });
  //   } else {
  //     ref.current.querySelectorAll("div").forEach((el) => {
  //       el.classList.remove("slide-in");
  //     });
  //   }
  // }, [isIntersecting]);
  console.log("====================================");
  console.log(isIntersecting);
  console.log("====================================");
  return (
    <main className="bg-bg1 w-full relative" id="landing-page">
      <nav className=" flex justify-between py-7 px-10 phone:px-5 top-0 z-50 bg-transparent">
        <div className="my-auto">
          <Logo />
        </div>

        <Button
          name={"secondary"}
          label={"Sign In"}
          size={"medium"}
          onClick={() => navigate("./coming-soon")}
          className={"w-[200px] phone:w-auto"}
        />
      </nav>
      {!isIntersecting && (
        <nav className=" absolute flex justify-between py-7 px-10 phone:px-5 top-0 z-50 bg-transparent">
          <div className="my-auto">
            <Logo />
          </div>

          <Button
            name={"primary"}
            label={"Get Started"}
            size={"small"}
            onClick={() => navigate("./coming-soon")}
            className={"w-[200px] phone:w-auto"}
          />

          <Button
            name={"secondary"}
            label={"Sign In"}
            size={"small"}
            onClick={() => navigate("./coming-soon")}
            className={"w-[200px] phone:w-auto"}
          />
        </nav>
      )}
      <div
        className="flex smallLaptop:flex-wrap justify-between smallLaptop:justify-center w-full gap-36 smallLaptop:gap-10"
        ref={ref}
      >
        <section className="w-full max-w-[560px] laptop:pl-10 mt-40 h-[476px] largeTablet:mt-12 smallLaptop:w-4/5 smallTablet:h-auto phone:w-10/12">
          <h1 className="font-extrabold text-left text-6xl leading-[82px] mb-7 phone:text-3xl phone:leading-10 phone:mx-auto phone:w-[268px] smallLaptop:text-center">
            Connect Your Pets To A{" "}
            <span className=" line-through text-prim_contrst_2 decoration-2">
              Sitter
            </span>{" "}
            <span className="text-prim2">Friend</span>
          </h1>
          <p className="text-text_subtle text-xl font-bold mb-5 smallLaptop:text-center phone:text-base leading-[21px]">
            {" "}
            Welcome to Furward, where your pets find their perfect sitters. We
            know how important your furry friends are to you, and that's why
            we're committed to connecting you with trusted, experienced pet
            sitters who treat your pets like family.
          </p>
          <div className="flex justify-center w-full mb-5">
            <Button
              name={"primary"}
              label={"Get Started"}
              size={"large"}
              className={"lndng-btn phone:w-[170px]"}
              onClick={()=>navigate("./coming-soon")}
            />
          </div>
          <div className="flex smallLaptop:justify-center w-full">
            <p className="text-text_subtle text-base font-medium leading-5 flex phone:text-[14px] phone:leading-[19px]">
              <span className="mr-2 -mt-1">
                <Icon id="paw-icon" width={"24px"} height={"24px"} />
              </span>
              Trusted by 100k+ pet owners
            </p>
          </div>
        </section>
        <section className="w-full max-w-[957px] relative smallLaptop:w-4/5 smallLaptop:mx-auto">
          {/* <HeroImg width={'100%'}/> */}
          <img
            src={require("../assets/Hero_img.jpg")}
            className="w-full h-full object-fill smallLaptop:mx-auto smallLaptop:w-11/12"
          />
          <aside className="flex gap-2.5 rounded-[20px] p-2.5 absolute bottom-2 left-2 bg-white phone:hidden smallLaptop:left-10">
            <h1 className="rounded-full bg-prim2 w-[45px] h-[45px] font-bold text-base leading-[22px] text-white text-center py-[11.5px] px-[8.5px]">
              {" "}
              30k
            </h1>
            <p className="text-base leading-[22px] font-semibold text-text_subtle pt-3">
              Pet Sitters waiting for you
            </p>
          </aside>
        </section>
      </div>
      <section className="bg-bg1 pt-[100px] pb-[50px] px-[50px]">
        <section className="w-full max-w-80 mx-auto mb-[50px]">
          <h1 className="text-4xl leading-[49px] text-center font-bold">
            <span className="text-primary">How</span> it works
          </h1>
          <p className="text-center text-xl text-text_subtle font-bold">
            Easy ways to get you pet a sitter on Furward
          </p>
        </section>
        <ul className="style-none w-full grid grid-cols-3 gap-3 tablet:grid-cols-1 justify-center">
          <li className="rounded-[20px] border border-br1 w-full max-w-[508px] tablet:mx-auto bg-br1 cursor-pointer">
            <img
              className="w-full rounded-t-[20px]"
              src={require("../assets/services1.jpg")}
              alt="search illustration"
            />

            <p className="p-5 text-center font-bold text-lg leading-6">
              Enter your location and browse through verified pet sitters.
            </p>
          </li>
          <li className="rounded-[20px] border border-br1 w-full max-w-[508px] tablet:mx-auto bg-br1 cursor-pointer">
            <img
              className="w-full rounded-t-[20px]"
              src={require("../assets/services2.jpg")}
              alt="search illustration"
            />

            <p className="p-5 text-center font-bold text-lg leading-6">
              View profiles, read reviews, and chat with potential sitters
            </p>
          </li>
          <li className="rounded-[20px] border border-br1 w-full max-w-[508px] tablet:mx-auto bg-br1 cursor-pointer">
            <img
              className="w-full rounded-t-[20px]"
              src={require("../assets/services3.jpg")}
              alt="search illustration"
            />

            <p className="p-5 text-center font-bold text-lg leading-6">
              Schedule and confirm your booking in just a few clicks.
            </p>
          </li>
        </ul>
      </section>
      <section className="bg-dark pt-[100px] pb-[50px] px-[50px]">
        <section className="w-full max-w-80 mx-auto mb-14">
          <h1 className="text-4xl leading-[49px] text-center font-bold text-white">
            Our <span className="text-primary">Services</span>
          </h1>
          <p className="text-center text-xl text-text_subtle font-bold">
            Welcome to Furward, where your pets find their perfect sitters.{" "}
          </p>
        </section>
        <ul className="style-none w-full flex flex-col justify-center gap-20 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          <li className="rounded-[100px] w-full max-w-[506px] bg-white mx-auto phone:rounded-[20px] relative overflow-hidde shadow-lg group">
            <div
              className="list1 relative"
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <img
                className="w-full rounded-t-[100px] h-[480px] main-image object-cover mb-25px phone:rounded-t-[20px]"
                src={require("../assets/services4.jpg")}
                alt="search illustration"
              />

              {/* <div className={`overlay-images ${isHovered ? "visible" : ""}`}>
                <img
                  src={image1}
                  alt="Image 1"
                  className="overlay-img overlay-img1"
                />
                <img
                  src={image2}
                  alt="Image 2"
                  className="overlay-img overlay-img2"
                />
                <img
                  src={image3}
                  alt="Image 3"
                  className="overlay-img overlay-img3"
                />
                <img
                  src={image4}
                  alt="Image 4"
                  className="overlay-img overlay-img4"
                />
              </div> */}
            </div>
            <section className="w-full max-w-[335px] mx-auto py-[30px]">
              <h1 className="text-3xl leading-[41px] text-center font-bold mb-1">
                Pet Sitting
              </h1>
              <p className="text-center text-lg leading-6 text-text_subtle font-bold">
                Daily care for your pets in the comfort of your home.
              </p>
            </section>
          </li>

          <li className="rounded-[100px] w-full max-w-[508px] bg-white mx-auto phone:rounded-[20px]">
            <img
              className="w-full rounded-t-[100px] h-[480px] object-cover mb-25px phone:rounded-t-[20px]"
              src={require("../assets/services5.jpg")}
              alt="search illustration"
            />

            <section className="w-full max-w-[335px] mx-auto py-[30px]">
              <h1 className="text-3xl leading-[41px] text-center font-bold mb-1">
                Dog Walking
              </h1>
              <p className="text-center text-lg leading-6 text-text_subtle font-bold">
                Regular walks to keep your dog happy and healthy.
              </p>
            </section>
          </li>
          <li className="rounded-[100px] w-full max-w-[508px] bg-white mx-auto phone:rounded-[20px]">
            <img
              className="w-full rounded-t-[100px] h-[480px] object-cover mb-25px phone:rounded-t-[20px]"
              src={require("../assets/services6.jpg")}
              alt="search illustration"
            />

            <section className="w-full max-w-[335px] mx-auto py-[30px]">
              <h1 className="text-3xl leading-[41px] text-center font-bold mb-1">
                House Sitting & Errands
              </h1>
              <p className="text-center text-lg leading-6 text-text_subtle font-bold">
                Complete care of your home, affairs, and pets while you're away.
              </p>
            </section>
          </li>
        </ul>
      </section>
      <section className="bg-matte_black p-[30px] pb-[50px]">
        <section className="w-full max-w-80 mx-auto mb-14">
          <p className="text-center text-xl text-primary font-bold">
            Success Stories
          </p>
          <h1 className="text-4xl leading-[49px] text-center font-bold text-white">
            How Furward changed lives{" "}
          </h1>
        </section>
        <ul className="style-none w-full grid grid-cols-2 gap-4 tablet:grid-cols-1">
          <li className="rounded-[30px] bg-dark mx-auto px-4 py-7 min-h-[300px] relative phone:px-4">
            <p className="text-white font-bold text-2xl">
              "<span className="text-primary">Furward</span> matched us with the
              perfect sitter for our two cats. Highly recommend!"
            </p>
            <div className="absolute bottom-4">
              <p className="text-white font-bold text-lg leading-[24.5px]">
                <span className="text-primary">-</span> Sarah L.
              </p>
            </div>
          </li>
          <li className="rounded-[30px] bg-dark mx-auto px-4 py-7 min-h-[300px] relative phone:px-4">
            <p className="text-white font-bold text-2xl">
              "Our dog loves his daily walks with{" "}
              <span className="text-primary">Furward</span>. Great service!”
            </p>
            <div className="absolute bottom-4">
              <p className="text-white font-bold text-lg leading-[24.5px]">
                <span className="text-primary">-</span> John D.
              </p>
            </div>
          </li>
          <li className="rounded-[30px] bg-dark mx-auto px-4 py-7 min-h-[300px] relative phone:px-4">
            <p className="text-white font-bold text-2xl">
              "We felt so comfortable leaving our home and pets in{" "}
              <span className="text-primary">Furward's</span> hands."
            </p>
            <div className="absolute bottom-4">
              <p className="text-white font-bold text-lg leading-[24.5px]">
                <span className="text-primary">-</span> Emily R.
              </p>
            </div>
          </li>
          <li className="rounded-[30px] bg-dark mx-auto px-4 py-7 min-h-[300px] relative phone:px-4">
            <p className="text-white font-bold text-2xl">
              "Fantastic experience! Our pets were so well cared for, and we
              received regular updates."
            </p>
            <div className="absolute bottom-4">
              <p className="text-white font-bold text-lg leading-[24.5px]">
                <span className="text-primary">-</span> Rachel M.
              </p>
            </div>
          </li>
          <li className="rounded-[30px] bg-dark mx-auto px-4 py-7 min-h-[300px] relative phone:px-4">
            <p className="text-white font-bold text-2xl">
              "We were nervous about leaving our pets, but{" "}
              <span className="text-primary">Furward's</span> sitter was
              excellent and put us at ease immediately."
            </p>
            <div className="absolute bottom-4">
              <p className="text-white font-bold text-lg leading-[24.5px]">
                <span className="text-primary">-</span> Mark H.
              </p>
            </div>
          </li>
          <li className="rounded-[30px] bg-dark mx-auto px-4 py-7 min-h-[300px] relative phone:px-4">
            <p className="text-white font-bold text-2xl">
              "Booking was easy, and the sitter was very professional. Highly
              recommend for any pet owner."
            </p>
            <div className="absolute bottom-4">
              <p className="text-white font-bold text-lg leading-[24.5px]">
                <span className="text-primary">-</span> Laura G.
              </p>
            </div>
          </li>
        </ul>
      </section>
      <section className="bg-primary w-full h-[624px] flex flex-col justify-center illustr px-4">
        <div className="w-full ">
          <h1 className="text-center text-white font-bold text-4xl leading-[49px] mb-2">
            Ready to Give Your Pet the Best Care?
          </h1>
          <p className="text-center text-white font-bold text-2xl mb-[50px]">
            Join Furward today and find the perfect sitter for your furry
            friend!
          </p>
          <section className={"mx-auto w-full max-w-[522px] phone:max-w-64 "}>
            <Button label={"Get Started"} name={"tertiary"} size={"large"} onClick={()=>navigate("./coming-soon")}/>
          </section>
        </div>
      </section>
      <footer className="flex justify-center bg-white">
        <section className="w-10/12 mx-auto tablet:w-full">
          <aside className="flex justify-between w-full phone:flex-wrap">
            <Logo2 />
            <ul className="ml-auto grid grid-cols-4 phone:grid-cols-3 justify-end gap-4 list-none mb-5 mt-4 text-right phone:text-left phone:ml-0 phone:gap-6">
              <li className="text-base leading-[22.4px] inter cursor-pointer font-medium">
                Pet Sitters
              </li>
              <li className="text-base leading-[22.4px] inter cursor-pointer font-medium">
                Pet Owners
              </li>
              <li className="text-base leading-[22.4px] inter cursor-pointer font-medium">
                About Us
              </li>
              <li className="text-base leading-[22.4px] inter cursor-pointer font-medium text-br3">
                Contacts
              </li>
            </ul>
          </aside>
          <div className="w-full border-t border-br2 " />
          <div className="ml-auto flex gap-0 w-44">
            <div className="my-auto py-5">
              <Icon
                id="envelope-icon"
                width={"20px"}
                height={"14px"}
                className="mt-[1px]"
              />
            </div>
            <p className="text-base font-medium inter flex py-5 ml-auto">
              info@furward.com
            </p>
          </div>
        </section>
      </footer>
    </main>
  );
};

export default Landing;
