import React from "react";
import {TailSpin} from "react-loader-spinner";

export const ButtonLoader = ({ color }) => (
  <div data-testid="loader2" className="w-6 m-a">
    <TailSpin 
        color={color || "#fff"} 
        height="21" 
        width="21" 
        visible={true}
        ariaLabel="tail-spin-loading"
        radius="1"
    />
  </div>
);