import React, { useState } from 'react';
import { ReactComponent as Logo } from "../assets/logo.svg";
import InputSelect from '../components/InputSelect';
import { useFormik } from 'formik';
import { JoinWaitlistValidation } from '../validation';
import Button from '../components/Button';
import InputField from '../components/Input';
import { useNavigate } from 'react-router-dom';
import { collection, addDoc } from "firebase/firestore";
import { fireBaseData } from '../config/firebase';
import { toastError } from '../components/Toast';

const ComingSoon = () => {
    const navigate = useNavigate();
    const [Loading, setLoading] = useState(false);
    const [Step, setStep] = useState(0);

    const handleClick = async(values) => {
        setLoading(true);
        try {
            const docRef = await addDoc(collection(fireBaseData, "waitlist"), values);
            if(docRef.id){
                setStep(1);
                setLoading(false);
            } else {
                toastError(`Error joining wailist`);
                setLoading(false);
            }
          } catch (e) {
            toastError(`Error joining wailist: ${e}`)
        }
    };
    const { handleChange, handleSubmit, handleBlur, values, errors, touched, setFieldValue, isValid } =
      useFormik({
        validationSchema: JoinWaitlistValidation,
        initialValues: {
          email: "",
          account_type: ""
        },
        onSubmit: () => handleClick(values),
        onReset: () => null
    });
    return ( 
        <main className="bg-gradient-to-r from-bg7 to-bg8 flex justify-between overflow-none h-screen w-full p-5 largeTablet:block largeTablet:justify-center pr-[10%] phone:pr-5 phone:pb-10 ">
            {!Step ? (
                <>
                    <img className="w-1/2 max-w-[800px] object-cover largeTablet:hidden" src={require("../assets/coming-soon1.png")} alt="Photo by Sam Lion: https://www.pexels.com/photo/funny-dog-dressed-like-doctor-in-studio-5732454/" />
                    <section className='w-1/2 max-w-[599px] my-auto largeTablet:w-full largeTablet:mx-auto'>
                        <aside>
                            <div className="mb-10 cursor-pointer" onClick={()=>navigate("/")} >
                                <Logo />
                            </div>
                            <h1 className='font-bold text-5xl leading-[65px] mb-2 phone:text-4xl phone:leading-[50px]'>We Are Treating Your <span className='text-primary'>#1</span> Pet Sitting Platform</h1>
                            <p className='font-bold text-lg leading-6 text-grey mb-[50px]'>Let us notify you immediately we are ready</p>
                            <form onSubmit={handleSubmit}>
                                <div className='flex mb-4 w-full'>
                                    <p className='font-bold text-lg leading-6 my-auto w-20'> I am a</p>
                                    <InputSelect
                                        name="account_type"
                                        placeholder="Select an option"
                                        value={values.account_type}
                                        data={[{label:'Pet Sitter',value:'PET_SITTER'},{label:'Pet Owner',value:'PET_OWNER'}]}
                                        onChange={(selectedOption)=>setFieldValue("account_type",selectedOption.value)}
                                    />
                                </div>
                                <InputField
                                    name="email"
                                    type="email"
                                    className="mb-[50px]"
                                    placeholder="Email Address"
                                    autoComplete="off"
                                    value={values.email}
                                    onChange={handleChange("email")}
                                    onBlur={handleBlur("email")}
                                    error={errors.email}
                                    touched={touched.email}
                                />
                                <Button
                                    label={"Join Waitlist"}
                                    name={"primary"}
                                    size={"large"}
                                    isLoading={Loading}
                                    disabled={!isValid}
                                />
                            </form>
                        </aside>
                    </section>
                </>
            ) : (
                <>
                    <img className="w-1/2 max-w-[800px] object-cover largeTablet:hidden" src={require("../assets/coming-soon2.png")} alt="Photo by Sam Lion: https://www.pexels.com/photo/funny-dog-dressed-like-doctor-in-studio-5732454/" />
                    <section className='w-1/2 max-w-[599px] my-auto largeTablet:w-full largeTablet:mx-auto'>
                        <aside>
                            <div className="mb-5 phone:mb-10 cursor-pointer" onClick={()=>navigate("/")} >
                                <Logo />
                            </div>
                            <h1 className='font-bold text-5xl leading-[65px] mb-2 phone:text-4xl phone:leading-[50px]'>You will be notified</h1>
                            <p className='font-bold text-lg leading-6 text-grey mb-[50px]'>Thank you for joining our waitlist</p>
                            
                            <Button
                                label={"Okay"}
                                name={"primary"}
                                size={"large"}
                                onClick={()=>navigate("/")}
                            />
                        </aside>
                    </section>
                </>
            )}
        </main>
     );
}
 
export default ComingSoon;