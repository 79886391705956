const BASE_URL = {
  production: "https://dev-api.myfurward.com/api/v1",
  staging: "https://dev-api.myfurward.com/api/v1",
  dev: "http://50.18.36.43:8000/api/v1",
};
  
export const MIXPANEL_KEY = {
  dev: process.env.REACT_APP_MIXPANEL_KEY_DEV,
  staging: process.env.REACT_APP_MIXPANEL_KEY_STAGING,
  production: process.env.REACT_APP_MIXPANEL_KEY_PRODUCTION
};
  
export default BASE_URL;