import React from 'react';
import Select from "react-select";


const customStyles = {
    control: (provided, state) => ({
      ...provided,
      border: "1px solid #E8E8EA",
      width: "100%",
      height: "auto",
      minHeight: "40px",
      borderRadius: "18px",
      padding: "8px 16px",
      background:"#FFFFFF",
      transitionDuration: '300ms',
      transitionProperty: 'box-shadow',
      transitionTimingFunction: 'cubic-bezier(0.4,0,1,1)',
      ':focus': { border: "0.75px solid #EC008C", transition: "all 0.4s" },
      'hover:': { border: "0.75px solid #EC008C" },
      boxShadow: 'none',
    }),
    // valueContainer: (provided) => ({
    //   ...provided,
    //   height: '40px'
    // }),
    placeholder: (provided) => ({
      ...provided,
      color: "#A5A3A9",
      fontSize: '14px',
      lineHeight: '16.24px',
    }),
    dropdownIndicator: (provided) => ({ ...provided, color: "#000000" }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    singleValue: (provided) => ({...provided, overflow: "unset"}),
    menu: (provided) => ({ ...provided,  }),
    option: (provided, state) => ({
      ...provided,
      ':active': { fontStyle: "bold",backgroundColor:"none" },
      ':hover': { opacity: "0.5", backgroundColor:"none" },
      backgroundColor: "white",
      color: state.isSelected? "inherit" : "inherit",
    }),
  }

  const InputSelect = ({ options, ...otherProps}) => {

    return (
        <div className="relative mb-2 w-full">
          {otherProps.label && <p className="mb-2">{otherProps.label}</p>}
            <div className="rel-2">
                <Select
                  id={otherProps.name}
                  options={otherProps.data}
                  defaultValue={
                      (otherProps.value ? { label: otherProps.value, value: otherProps.value } : otherProps.placeholder)
                  }
                  maxMenuHeight={200}
                  formatOptionLabel={otherProps.formatOptionLabel || null}
                  name={otherProps.name}
                  onBlur={otherProps.onBlur}
                  styles={customStyles}
                  placeholder={ otherProps.value? "" : otherProps.placeholder}
                  onChange={otherProps.onChange}
                  isMulti={otherProps.isMulti}
                  classNames={{
                    control: (state) =>
                      state.isFocused ? 'border-primary' : 'border-grey-300',
                  }}
                />
            </div>

        </div>
    )
}

export default InputSelect;