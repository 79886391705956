import React from "react";
import Icons from "../../assets/svg/icon-sprite.svg"

const Icon = ({ id, height, width, ...props }) => (
  <svg width={width || "40px"} height={height || "40px"} {...props}>
    <use
      xlinkHref={Icons + `#${id}`}
    />
  </svg>
);

export default Icon;
