import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import "../App.css";
import LandingPage from "../pages/Landing";
import SignIn from "../pages/auth/SignIn";
import Register from "../pages/auth/Register";
import Onboarding from "../pages/onboarding";
import UserDetails from "../pages/onboarding/UserDetails";
import Dashboard from "../pages/Dashboard";
import PetDetails from "../pages/onboarding/PetDetails";
import Services from "../pages/onboarding/Services";
import Appointments from "../pages/Appointments";
import ExploreSitters from "../pages/explore/PetSitters";
import Pets from "../pages/Pets";
import Sitters from "../pages/Sitters";
import ViewSitter from "../pages/Sitters/ViewSitter";
import ViewPet from "../pages/Pets/ViewPet";
import AddPet from "../pages/Pets/AddPet";
import Profile from "../pages/Profile";
import AddAppointments from "../pages/Appointments/AddAppointments";
import Settings from "../pages/Settings";
import ViewAppointment from "../pages/Appointments/components/ViewAppointment";
import ComingSoon from "../pages/ComingSoon";

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
  },
  {
    path: "/coming-soon",
    element: <ComingSoon />,
  },
  // {
  //   path: "/login",
  //   element: <SignIn />,
  // },
  // {
  //   path: "/register",
  //   element: <Register />,
  // },
  // {
  //   path: "/onboarding",
  //   element: <Onboarding />,
  // },
  // {
  //   path: "/onboarding/petowner/basic-details",
  //   element: <UserDetails />,
  // },
  // {
  //   path: "/onboarding/petsitter/basic-details",
  //   element: <UserDetails />,
  // },
  // {
  //   path: "/onboarding/petowner/pet-details",
  //   element: <PetDetails />,
  // },
  // {
  //   path: "/onboarding/petsitter/services",
  //   element: <Services />,
  // },
  // {
  //   path: "/dashboard",
  //   element: <Dashboard />,
  // },
  // {
  //   path: "/appointments",
  //   element: <Appointments />,
  // },
  // {
  //   path: "/appointments/create",
  //   element: <AddAppointments />,
  // },
  // {
  //   path: "/appointments/:id",
  //   element: <ViewAppointment />,
  // },
  // {
  //   path: "/explore",
  //   element: <ExploreSitters />,
  // },
  // {
  //   path: "/pets",
  //   element: <Pets />,
  // },
  // {
  //   path: "/pets/:id",
  //   element: <ViewPet />,
  // },
  // {
  //   path: "/pets/create",
  //   element: <AddPet />,
  // },
  // {
  //   path: "/sitters",
  //   element: <Sitters />,
  // },
  // {
  //   path: "/sitters/:id",
  //   element: <ViewSitter />,
  // },
  // {
  //   path: "/profile",
  //   element: <Profile />,
  // },
  // {
  //   path: "/settings",
  //   element: <Settings />,
  // },
]);

export default router;
